<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Normal Distribution"
            right-nav="reset"
            @click="doReset"
        />
        <div>
            <div
                v-if="tabIndex > 0"
                class="inline-input-view d-flex"
            >
                <input-single
                    v-model="μ"
                    :tip="getTip('μ')"
                    label="μ"
                    style-class="input-single inline"
                    group
                    @enter-pressed="onSubmit()"
                />
                <input-single
                    v-model="σ"
                    :tip="getTip('σ')"
                    label="σ"
                    style-class="input-single inline"
                    group
                    @enter-pressed="onSubmit()"
                />
                <button
                    v-if="tabIndex === 1"
                    class="action-btn m-auto"
                    @click="showModal()"
                >
                    Formula
                </button>
                <input-single
                    v-if="tabIndex === 2"
                    v-model="n"
                    :tip="getTip('n')"
                    label="n"
                    style-class="input-single inline"
                    group
                    @enter-pressed="onSubmit()"
                />
            </div>

            <b-tabs
                v-model="tabIndex"
                class="mb-3"
                justified
                pills
                end
                lazy
                @activate-tab="tabChanged($event)"
            >
                <b-tab
                    v-for="tab in getTabs"
                    :key="tab.id"
                    :title="tab.name"
                />
                <div class="m-view d-flex">
                    <h3>P({{ screenChar }} </h3>
                    <select
                        v-model="selected.value"
                        v-b-tooltip.hover.bottom="getTip('picker')"
                        class="form-select mx-2"
                        @change="onChangePicker()"
                    >
                        <option
                            v-for="(option, key) in options"
                            :key="key"
                        >
                            {{ option.value }}
                        </option>
                    </select>
                    <input
                        v-model="z"
                        v-b-tooltip.hover.bottom="getTip('z')"
                        class="eq-inline-input"
                        placeholder="?"
                        @input="onChangeZ()"
                        @keyup.enter="onSubmit()"
                    >
                    <h3> )=</h3>
                    <input
                        v-model="prob"
                        v-b-tooltip.hover.bottom="getTip('prob')"
                        class="eq-inline-input"
                        placeholder="?"
                        @input="onChangeProb()"
                        @keyup.enter="onSubmit()"
                    >
                </div>

                <h4 class="mt-2">
                    — OR —
                </h4>

                <div class="m-view d-flex">
                    <h3>P( </h3>
                    <input
                        v-model="zLow"
                        v-b-tooltip.hover.bottom="getTip('zLow')"
                        class="eq-inline-input"
                        placeholder="?"
                        @input="onChangeBetween()"
                        @keyup.enter="onSubmit()"
                    >
                    <h3>≤ {{ screenChar }} ≤</h3>
                    <input
                        v-model="zHigh"
                        v-b-tooltip.hover.bottom="getTip('zHigh')"
                        class="eq-inline-input"
                        placeholder="?"
                        @input="onChangeBetween()"
                        @keyup.enter="onSubmit()"
                    >
                    <h3>) =</h3>
                    <input
                        v-model="probC"
                        v-b-tooltip.hover.bottom="getTip('prob')"
                        class="eq-inline-input"
                        placeholder="?"
                        @input="onChangeBetweenProb()"
                        @keyup.enter="onSubmit()"
                    >
                </div>
                <highcharts
                    class="pt-2 pb-4 m-auto"
                    :options="zChart.chartOptions"
                />
                <h5 class="my-3 text-left">
                    Variable of Interest :
                </h5>
            </b-tabs>
        </div>
        <modal
            ref="helpModal"
            title="Formula"
            style-class="modal-bottom"
            modal-type="general"
        >
            <template #body>
                <img
                    class="formulaImage"
                    src="@/assets/helpImgs/NormalDistribution.jpg"
                >
            </template>
        </modal>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import Modal from '@/components/modal.vue'
import InputSingle from '@/components/input-single.vue'
import normalData from '@/js/distributions/normal-data.js'
import { isEmptyNumsCustom, clearData } from '@/js/lib/input-check.js'
import { tips } from '@/js/lib/tooltip-text.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'

export default {
    name: 'NormalDistribution',
    components: {
        TopNavigationBar,
        highcharts: Chart,
        InputSingle,
        Modal
    },
    data () {
        return {
            tabIndex: 0,
            μ: '', // mu (Mean)
            σ: '', // sigma (Standard Deviation)
            n: '', // sample size (xBar)
            // Greater & Less
            z: '', // Point
            prob: '', // Probability
            // Between
            zLow: '', // Point Low (Left)
            zHigh: '', // Point High (Right)
            probC: '', // Probability
            options: [
                { value: '≥' },
                { value: '>' },
                { value: '≤' },
                { value: '<' },
            ],
            selected: { value: '≤' },
            graphData: []
        }
    },
    computed: {
        getTabs () {
            return [
                { name: 'Z', id: 1 },
                { name: 'X', id: 2 },
                { name: 'X̄', id: 3 }
            ]
        },
        screenChar () {
            var text
            _.mapValues(this.getTabs, (v, k) => {
                if (_.toNumber(k) === this.tabIndex) {
                    text = v.name
                }
            })
            return text
        },
        zChart () {
            var { graphData } = this
            return {
                chartOptions: {
                    chart: {
                        type: 'area',
                        spacing: [null, 30, 35, 30],
                        spacingTop: -20,
                        height: 350
                    },
                    xAxis: {
                        endOnTick: true,
                        startOnTick: true,
                        tickLength: 0,
                        tickInterval: 0.001,
                        max: _.get(graphData, 'max', 3),
                        min: _.get(graphData, 'min', -3),
                        labels: {
                            enabled: false,
                        },
                        plotLines: _.get(graphData.zones, 'plotLineVals', [])
                    },
                    yAxis: {
                        visable: false,
                        lineWidth: 0,
                        tickLength: 0,
                        labels: {
                            enabled: false
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                    legend: {
                        enabled: false,
                    },
                    series: [
                        { data: _.get(graphData, 'series', normalData.loadGraphSeries(0, 1)) },
                    ],
                    plotOptions: {
                        area: {
                            zoneAxis: 'x',
                            zones: [{
                                fillColor: 'white',
                                value: _.get(graphData.zones, 'zLowZone', [])
                            }, {
                                value: _.get(graphData.zones, 'zHighZone', [])
                            }, {
                                fillColor: 'white',
                            }]
                        }
                    }

                }
            }
        },
    },
    methods: {
        getTip (key) {
            var tipsData = _.merge(tips.general, _.get(tips.normal, this.screenChar))
            return _.get(tipsData, key)
        },
        doReset (keepData) {
            var inputs = ['z', 'prob', 'zLow', 'zHigh', 'probC']
            if (!keepData) {
                inputs = _.concat(inputs, ['μ', 'σ', 'n', 'graphData'])
            } else {
                inputs = _.difference(inputs, keepData)
            }
            _.assign(this, clearData(this, inputs))
        },
        showModal () {
            this.$refs.helpModal.show()
        },
        active (tab) {
            return tab.active
        },
        tabChanged (event) {
            this.doReset()
        },
        onChangeZ () {
            this.doReset(['z'])
        },
        onChangeProb () {
            this.doReset(['prob'])
        },
        onChangeBetween () {
            this.doReset(['zLow', 'zHigh'])
        },
        onChangeBetweenProb () {
            this.doReset(['probC'])
        },
        onChangePicker () {
            if (isEmptyNumsCustom(this.z, this.prob)) { return }
            this.onSubmit()
        },
        onSubmit () {
            var { μ, σ, n, tabIndex } = this
            var output
            var graphOutput
            if (tabIndex === 2) { // xBar
                if (!(normalData.checkN(μ, σ, n) || normalData.checkMuSigma(μ, σ))) {
                    output = normalData.calculateData(this, μ, σ)
                }
            } else if (tabIndex === 1) { // x
                if (!normalData.checkMuSigma(μ, σ)) {
                    output = normalData.calculateData(this, μ, σ)
                }
            } else { // z
                μ = 0
                σ = 1
                output = normalData.calculateData(this, μ, σ)
            }
            if (!output) {
                return
            }
            _.assign(this, output)
            graphOutput = normalData.calculateGraphData(this, μ, σ)
            _.set(this, 'graphData', graphOutput)
        },
    }
}
</script>
